/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import Layout from "../components/layout"
import PostCard from "../components/post-card"
import Seo from "../components/seo"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

export const pageQuery = graphql`
  query GPMCQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { template: { eq: "media" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            featuredImage {
              publicURL
            }
          }
        }
      }
    }
  }
`
const MediaClippingGallery = ({ data }) => {
  const { markdownRemark, posts } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  console.log(posts)
  return (
    <Layout className="page">
      <Seo title={frontmatter?.title} description={excerpt} />
      <div className="container">
        <div style={{ justify: "center" }}>
          <div className="masonry-container">
            {posts.edges.map(elem => (
              <div className="item">
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Zoom>
                    <img
                      src={elem.node.frontmatter.featuredImage.publicURL}
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        maxHeight: "500px",
                      }}
                    ></img>
                  </Zoom>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MediaClippingGallery
